var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scores-list"},_vm._l((_vm.scoresData),function(ref){
var scoreValue = ref.scoreValue;
var name = ref.name;
var color = ref.color;
var scoreProgress = ref.scoreProgress;
var scoreConfig = ref.scoreConfig;
return _c('div',{key:name,staticClass:"score u-typography-helvetica u-text u-text--s"},[_c('span',{staticClass:"score__label "},[_vm._v(_vm._s(_vm.$t(name)))]),_c('progress-bar',{staticClass:"score__progress",attrs:{"value":scoreProgress,"color":color}}),_c('div',{staticClass:"score__value",class:scoreConfig.scoreClass},[_c('span',[_vm._v(" "+_vm._s(scoreValue)+"/10")]),(scoreConfig)?_c('u-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t(scoreConfig.tooltip),
          placement: 'left-start',
          classes: ['u-tooltip', 'q-tooltip-w300', 'u-typography-helvetica u-text u-text--xs']
        }),expression:"{\n          content: $t(scoreConfig.tooltip),\n          placement: 'left-start',\n          classes: ['u-tooltip', 'q-tooltip-w300', 'u-typography-helvetica u-text u-text--xs']\n        }"}],staticClass:"score__icon",class:scoreConfig.iconClass,attrs:{"name":"alert-triangle"}}):_vm._e()],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }