<template>
  <div class="scores-list">
    <div
      v-for="{ scoreValue, name, color, scoreProgress, scoreConfig } in scoresData"
      :key="name"
      class="score u-typography-helvetica u-text u-text--s"
    >
      <span class="score__label ">{{ $t(name) }}</span>
      <progress-bar :value="scoreProgress" :color="color" class="score__progress" />
      <div class="score__value" :class="scoreConfig.scoreClass">
        <span> {{ scoreValue }}/10</span>
        <u-icon
          v-if="scoreConfig"
          v-tooltip="{
            content: $t(scoreConfig.tooltip),
            placement: 'left-start',
            classes: ['u-tooltip', 'q-tooltip-w300', 'u-typography-helvetica u-text u-text--xs']
          }"
          name="alert-triangle"
          class="score__icon"
          :class="scoreConfig.iconClass"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UIcon } from 'universkin-shared';
import ProgressBar from '@/modules/questionnaire/components/common/ProgressBar';

const SCORE_ORANGE = {
  scoreClass: 'score__value--orange',
  tooltip: 'preliminaryResults.tooltip.exclamationMarkOrange'
};

const SCORE_RED = {
  scoreClass: 'score__value--red',
  tooltip: 'preliminaryResults.tooltip.exclamationMarkRed'
};

export default {
  name: 'SkinDysfunctionResults',
  components: { ProgressBar, UIcon },
  props: {
    sectionsBinding: {
      type: Array,
      required: true
    },
    scores: {
      type: Object,
      required: true
    }
  },
  computed: {
    scoresData() {
      return this.sectionsBinding.map(dysfunction => ({
        ...dysfunction,
        scoreValue: this.scores[dysfunction.propertyName],
        scoreProgress: this.scores[dysfunction.propertyName] * 10,
        scoreConfig: this.getScoreConfig(this.scores[dysfunction.propertyName])
      }));
    }
  },
  methods: {
    getScoreConfig(score) {
      if (score < 7) {
        return '';
      }

      return score === 7 || score === 8 ? SCORE_ORANGE : SCORE_RED;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/common/icons';
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
.scores-list {
  padding: 0 10px;
}

.score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  color: var(--u-color-grey-900);

  &__label {
    width: 100%;
    margin-bottom: 8px;
  }

  &__progress {
    width: 175px;
    flex: 3;
    margin-right: 10px;
  }

  &__value {
    display: flex;
    flex: 1;
    align-items: center;

    &--orange {
      color: var(--u-color-orange-300);
      --u-icon-color: var(--u-color-orange-300);
    }

    &--red {
      color: var(--u-color-red-300);
      --u-icon-color: var(--u-color-red-300);
    }
  }

  &__icon {
    display: block;
    cursor: pointer;
    margin-left: 5px;
    width: 14px;
    height: 14px;
    text-align: right;
  }
}

@media (min-width: 370px) {
  .scores-list {
    padding: 0 15px;
  }

  .score {
    flex-wrap: nowrap;

    &__label {
      width: 130px;
      margin-bottom: 0;
      text-align: right;
    }

    &__progress {
      width: 102px;
      margin: 0 10px;
    }
  }
}

@media (min-width: $tablet-start) {
  .score {
    &__label {
      min-width: 137px;
    }

    &__progress {
      width: 260px;
      margin: 0 20px;
    }
  }
}
</style>
